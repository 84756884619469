import React from 'react'
import styled, { css } from 'styled-components'

const SVG = styled.svg`
  width: 100%;
  ${props =>
    props.small &&
    css`
      max-width: 30px;
    `};
  ${props =>
    props.white &&
    css`
      fill: white;
    `};
  ${props =>
    props.menu &&
    css`
      @media screen and (min-width: ${props => props.theme.responsive.medium}) {
        fill: white;
      }
    `};
`

const Monogram = props => {
  return (
    <SVG
      viewBox="0 0 830.8 915.3"
      {...props}
      role="img"
      aria-labelledby={props.title}
    >
      <title id={props.title}>Kirsten Noelle Monogram</title>
      <path
        d="M407.2,873c-0.7-19.1-0.7-36.3-1.2-55c-2.5-1.8-5.2-4-7.5-5.9c-13.1-11.1-26.1-22.4-38.2-34.5
	c-13.4-13.3-24.4-28.2-35.8-43.2c0,21.5,0.5,43.3,1.9,64.6c0.5,7,0.3,13.7,0.1,20.4c-0.3,6.9-0.2,13.7,0.4,20.6
	c2.5,25.3,1.8,48.6-3.2,73.2c-0.1,0.8-0.7,1.3-0.3,1.8s1.7,0.1,2.9,0.2c8.3,1,17.7,5.3,25.5,0.2c0.1-0.1,0-0.1,0-0.1
	c8.2,0,18.1,0,26,0c5.7,0,9.4,0,14.5,0c2.4,0,4.5,0,6.6,0c5.6,0,10.9,0,16.4-0.5c0.2,0,0.4,0,0.4-0.1s-0.1-0.3-0.1-0.4
	C411.1,901.1,407.6,886.4,407.2,873z"
      />
      <path
        d="M819.3,830.4c1-37.8,1.3-60,0.6-103.9c-0.5-34.2,0-85.8,1.4-129.2c0.8-24.4,2.4-69,2.7-97c0.5-38.5,2.1-88.7,1.6-124.8
	c-0.3-18.9,0.3-26.9,3.5-41.6c1-4.5-1.9-8.9-6.4-9.9c-0.5-0.1-1-0.2-1.5-0.2c-21.1-0.7-48.5,1.9-68.3,0.6c-4-0.3-7.5,2.8-7.8,6.8
	c0,0.4,0,0.8,0,1.3c0.9,8.8,4,11.2,3.9,27.8c-0.1,86.9-1.6,148.8-0.4,237.4c0.3,19.8-1.2,31.7-0.5,48c0.4,10.1,0.5,62.9,1.1,68.8
	c1,10.3-0.1,26.2-5.6,29.6c-11.3,6.9-22.4-17.5-29.2-23.6c-3.8-3.4-8.6-8.4-14.2-12.2s-8.2-11.8-11-14.7
	c-14.4-15.1-17.5-20.6-28.9-36.5c-13.7-19-31.8-39.9-47-59.7s-29.9-39.9-44.9-60.7c-4.3-6-11.9-16.6-16.3-22.8
	c-24.7-34.1-49-66.7-73.1-100.8c-0.9-1.2-2.3-3.3-3.2-4.6c-18.2-24.9-34.9-46.7-41-76.9c-0.5-2.8-3.1-4.7-5.9-4.4
	c-4.5,0.5-9.1,0.5-13.7,0c-13.6-1.6-27.3-2.2-41-1.7c-10.2,0.3-29.8-0.6-37.7-0.5s-11.3-3.1-15.3,1s-0.1,10.8-0.3,17.8
	c-0.1,3,1.6,12,2.2,17.8c1.5,13.9,3.9,29.5,4.7,44.5c2.4,44.1,1.3,88.6-0.3,133.1c-0.1,2.4-0.2,6.7-0.3,9.1
	c-0.3,7.9-0.7,15.6-1.1,23.3c-7.7-12.5-15.4-25-23.1-37.5c-29.5-47.6-60-96.7-86.5-147.4c-4.6-8.8-10-17.1-15.7-25.8
	c-6-9.3-12.3-18.9-17.6-29.2c-6-11.6-7.7-22,0.1-32.2c2.7-3.5,6.8-8.2,9.5-11.2c3.5-3.6,6.7-7.5,9.5-11.7
	c18.8-29.9,42.1-56.6,63.1-83.8l8.6-11.1c5-6.4,10.3-12.6,15.5-18.6c6.4-7.4,12.5-14.4,17.9-21.9c7.3-10.1,15.5-19.3,23.4-28.1
	c6.4-7.1,12.4-13.9,17.9-21c9-11.6,17.7-24,26-36c23.2-34,40.4-46.5,38.6-51.8S406-0.2,401.9,0c-19,0.5-37.8,3.4-56.1,2.4
	c-10.7-0.5-15.2-0.4-26-0.6c-2.5,0-9.6-0.8-10.6-0.8c-1.7-0.1-3.6-0.5-4.2,1.9c-0.5,1.7,1,6.9,0.7,9.2c-0.9,6.5-2,14.7-8,21.6
	c-4,4.9-7.7,10.1-11,15.6c-3.6,5.8-7.6,11.5-11.9,16.8c-6.1,7.4-12.3,14.7-18.5,22c-11,13-22.4,26.4-32.9,39.9
	c-23.7,30.5-48.6,63.6-80.9,107.4c-6.4,8.7-13.6,16.2-20.6,23.5c-4.2,4.4-8.2,8.6-12,13l-16.3,13.6c-2.4,2-5.9,1.7-7.9-0.7
	c-0.9-1-1.3-2.3-1.3-3.7c0.1-11.4,0.3-27,0.5-29.8c0.1-0.7,0.1-1.4,0.1-2.1c-0.1-6,0.1-12,0.2-18c0.2-8.6,0.4-16.7,0-24.8
	c-1.2-24.2,0.6-48.4,2.4-71.7c0.9-12.2,1.9-24.7,2.4-37l0.1-1.2c0.9-22.9,1.9-46.5,8.6-70c1.9-6.7,3.5-13.3,6.4-19.9
	c3.2-7.5-18.6-5.4-28.7-5.6c-21-0.3-42.1-0.6-63.1-1c-2.9-0.1-5.8,0-8.7,0.2C1.7,0.5-0.2,2.8,0,5.4c0,0.2,0,0.4,0.1,0.6
	c0.6,2.8,1.3,5.5,2.2,8.2C9.5,37.1,12.7,61.1,11.9,85l-0.1,2.5c-2.1,60.3-4.3,122.7-2,183.9c0.3,8.5,0.8,17,1.2,25.5
	c1.1,21.8,2.3,44.4,1.5,66.8c-0.4,10.8-0.7,21.6-1,32.4c-0.7,23.1-1.3,47-2.7,70.6c-1.6,27.9-1.4,56.5-1.2,84.2
	c0.2,31,0.3,62.8-1.8,94.6c-0.6,8-6.7,24.2-1.2,24.1c1,0,6.5,0,7.5,0l15.1,0.8c16.6,0.7,33.2,1.3,49.8,1.7c3.6,0.1,7.3,0,11.2-0.2
	c3.6-0.3,6.4-3.4,6.1-7.1c0-0.3-0.1-0.7-0.1-1c-4.6-19.9-8.9-41.6-10.4-60.5c-0.4-4.6-0.9-9.3-1.6-13.8
	c-6.5-41.6-5.6-82.1-3.8-127.3c0.6-14.6,2.9-32.1,2.4-45c-0.6-13.5,0.9-27.4,8.3-35.2s12-11.1,20.2-15.5c13.1-7,18.8,13,21.4,17
	c10.6,16.6,31.2,54.2,42,74.6c21.4,40.3,40.9,76.4,63.5,110.3c9.9,14.9,19.7,30.2,29.1,44.9c19.4,30.3,39.5,61.6,61.4,90.6
	c3,4,6,8,9,12.1c12,16.2,23.3,31.5,37.1,45c12,11.8,24.5,23,38.3,34.5c18,14.9,39.1,27.4,68.4,40.7c24.4,11,50.4,19.6,75.5,27.8
	c7.6,2.5,15.5,4.8,23.2,7c6.9,2,14,4,21,6.3c4.5,1.4,9.3-1,10.7-5.5c0.2-0.8,0.4-1.5,0.4-2.3c0.2-7,0.5-13.9,0.9-20.7
	c0.7-14,1.3-28.5,1.1-42.7c-0.1-2.7-2.3-4.8-5-4.8c-17.6-0.2-35.8-4.2-55.5-12.3c-23.4-9.7-46-21.3-67.5-34.9
	c-30.7-19.3-58.5-43-82.3-70.3c-0.4-11-0.8-21.9-0.9-32.2c-0.1-15.2,1.3-29.1,2.7-43.4c1.2-12.5,2.3-23.5,2.5-35.6
	c0.1-5.8-0.1-11.1-0.2-16.8c-0.4-13.2,0-26.3,1.4-39.4c0-0.2,0.1-1,0.1-1.1c0.5-7.1-2-37.2,5.9-38.6c13.9-2.5,19.3,20.5,25.9,28.6
	c0.4,0.5,0,0.2,0.6,0.8l2.5,2.6c2.5,2.5,4.8,5.2,7,8.1c4.9,6.7,9.7,13.4,14.8,20.2c13,17.5,25.8,35.5,38,53.7
	c31.7,47.1,66.9,92.2,102.4,136.2c14.5,17.9,28.4,33.9,43.2,51.2c25.5,29.9,49.9,61.2,73.5,92.6c6.8,8.9,12.3,18.8,16.4,29.2
	c0.5,1.2,1,4.6,2.4,5.5s5,0.6,6.8,0.6c14.7,0.6,28.5-0.1,43.4-0.7c12.8-0.5,26.6-1.2,39.6-0.9c0.2,0,0.6-0.1,0.8,0
	C822.6,878.8,818.6,858,819.3,830.4z"
      />
    </SVG>
  )
}

export default Monogram
