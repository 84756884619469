import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Logo from './Logo'
import { motion } from 'framer-motion'

const Nav = styled.nav`
  transition: max-height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1),
    background 0.3s, border 0.3s;
  background: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: ${(props) => (props.open ? '100%' : '56px')};
  width: 100%;
  z-index: 99;
  overflow-y: hidden;
  overscroll-behavior: contain;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    background: transparent;
    border-bottom: 1px solid transparent;
    max-height: none;
    bottom: auto;
    line-height: 56px;
  }
  a {
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.5em;
    text-decoration: none;
    color: ${(props) => props.theme.colors.base};
    border-bottom: 1px solid transparent;
    transition: opacity 0.3s;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      color: white;
      font-size: 0.85em;
      letter-spacing: 0.8px;
      height: 56px;
      padding: 0 0 0.1em 0;
    }
    &:hover {
      color: 0.7;
    }
    @media (hover: none) {
      opacity: 1 !important;
    }
  }
`

const List = styled(motion.ul)`
  list-style: none;
  padding: 6em 0 0 1em;
  text-align: left;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    padding: 6em 0 0 2em;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    padding: 0 2em;
    width: 100%;
    margin: 0 auto;
    max-width: ${(props) => props.theme.sizes.maxWidth};
    display: flex;
  }
  li:first-child {
    transform: none;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 1em;
    margin-right: auto;
    @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
      left: 2em;
    }
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      position: relative;
      top: auto;
      left: auto;
      margin-left: 0;
    }
  }
`

const Item = styled(motion.li)`
  margin-bottom: 1.25em;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    display: inline-block;
    transform: none;
    transition: none;
    opacity: 1 !important;
    margin: 0 0 0 1.25em;
    &:last-child {
      margin: 0;
    }
  }
`

const Toggle = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  transition: transform 0.3s;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    right: 1em;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    display: none;
  }
  span:first-child {
    transform: rotate(${(props) => (props.open ? '45deg' : '0')});
    top: ${(props) => (props.open ? '29px' : '23px')};
  }
  span:nth-child(2n) {
    transform: rotate(${(props) => (props.open ? '-45deg' : '0')});
    bottom: ${(props) => (props.open ? '25px' : '23px')};
  }
`

const Icon = styled.button`
  cursor: pointer;
  position: relative;
  float: right;
  width: 3.5rem;
  height: 3.5rem;
  span {
    transition: all 0.3s;
    position: absolute;
    display: block;
    background: ${(props) => (props.open ? 'white' : props.theme.colors.base)};
    width: 40%;
    height: 2px;
    left: 30%;
  }
`

const SVG = styled.svg`
  transition: 0.3s all;
  fill: ${(props) => props.theme.colors.base};
  display: inline-block;
  height: 2rem;
  width: 2rem;
  &:hover {
    opacity: 0.7;
  }
  @media (hover: none) {
    opacity: 1 !important;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    display: none;
  }
`

const Instagram = styled(SVG)`
  margin-left: 0 !important;
`

const Facebook = styled(SVG)`
  margin-left: -0.5rem !important;
`

const Pinterest = styled(SVG)`
  margin-right: -0.25rem !important;
`

const Overlay = styled(motion.div)`
  background: ${(props) => props.theme.colors.base};
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  display: block;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    display: none;
  }
`

const Menu = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isTop, setIsTop] = useState(true)

  function toggle() {
    setIsOpen(!isOpen)
    document.documentElement.classList.toggle('contain')
  }

  function close() {
    setIsOpen(false)
    document.documentElement.classList.remove('contain')
  }

  function handleScroll() {
    setIsTop(!window.pageYOffset)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const currentState = isOpen ? 'open' : 'closed'

  const overlayVariants = {
    open: {
      opacity: 0.8,
      visibility: 'visible',
      transition: {
        ease: 'easeInOut',
        duration: 0.35,
      },
    },
    closed: {
      opacity: 0,
      transition: {
        ease: 'easeInOut',
        duration: 0.35,
      },
      transitionEnd: {
        visibility: 'hidden',
      },
    },
  }

  const listVariants = {
    open: {
      transition: { staggerChildren: 0.05, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }
  const itemVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }

  return (
    <div className={isTop ? 'top' : 'scrolled'}>
      <Overlay
        variants={overlayVariants}
        initial="closed"
        animate={currentState}
      />
      <Nav open={isOpen} id="nav" role="navigation">
        <Toggle open={isOpen} aria-label="Toggle Menu">
          <Icon onClick={toggle}>
            <span />
            <span />
          </Icon>
        </Toggle>
        <List
          variants={listVariants}
          initial="closed"
          animate={currentState}
          {...props}
        >
          <li>
            <Link
              id="logo"
              aria-label="Home"
              to="/"
              style={{ display: 'flex' }}
              onClick={close}
            >
              <Logo small menu />
              <span hidden style={{ display: 'none' }}>
                Kirsten Noelle
              </span>
            </Link>
          </li>
          <Item variants={itemVariants}>
            <Link
              to="/about/"
              onClick={close}
              className="link--about"
              {...props}
            >
              About
            </Link>
          </Item>
          <Item variants={itemVariants}>
            <Link
              to="/wedding/"
              onClick={close}
              className="link--wedding"
              {...props}
            >
              Wedding
            </Link>
          </Item>
          <Item variants={itemVariants}>
            <Link
              to="/lifestyle/"
              onClick={close}
              className="link--lifestyle"
              {...props}
            >
              Lifestyle
            </Link>
          </Item>
          <Item variants={itemVariants}>
            <Link
              to="/wedding-blog/"
              onClick={close}
              className="link--blog"
              {...props}
            >
              Blog
            </Link>
          </Item>
          <Item variants={itemVariants}>
            <Link
              to="/contact/"
              onClick={close}
              className="link--contact"
              {...props}
            >
              Contact
            </Link>
          </Item>
          <Item variants={itemVariants}>
            <a
              href="https://www.facebook.com/kirstennoellew/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Kirsten Noelle Facebook"
              tabIndex={!isOpen ? '-1' : '0'}
            >
              <Facebook
                viewBox="0 0 512 512"
                role="img"
                aria-labelledby="headerFacebookID"
              >
                <title id="headerFacebookID">Facebook Icon</title>
                <path d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z" />
              </Facebook>
            </a>
            <a
              href="https://www.pinterest.com/BayAreaWeddingPhotographer/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Kirsten Noelle Pinterest"
              tabIndex={!isOpen ? '-1' : '0'}
            >
              <Pinterest
                viewBox="0 -5 34 34"
                role="img"
                aria-labelledby="headerPinterestID"
              >
                <title id="headerPinterestID">Pinterest Icon</title>
                <path d="M0 12c0 5.123 3.211 9.497 7.73 11.218-.11-.937-.227-2.482.025-3.566.217-.932 1.401-5.938 1.401-5.938s-.357-.715-.357-1.774c0-1.66.962-2.9 2.161-2.9 1.02 0 1.512.765 1.512 1.682 0 1.025-.653 2.557-.99 3.978-.281 1.189.597 2.159 1.769 2.159 2.123 0 3.756-2.239 3.756-5.471 0-2.861-2.056-4.86-4.991-4.86-3.398 0-5.393 2.549-5.393 5.184 0 1.027.395 2.127.889 2.726a.36.36 0 0 1 .083.343c-.091.378-.293 1.189-.332 1.355-.053.218-.173.265-.4.159-1.492-.694-2.424-2.875-2.424-4.627 0-3.769 2.737-7.229 7.892-7.229 4.144 0 7.365 2.953 7.365 6.899 0 4.117-2.595 7.431-6.199 7.431-1.211 0-2.348-.63-2.738-1.373 0 0-.599 2.282-.744 2.84-.282 1.084-1.064 2.456-1.549 3.235C9.584 23.815 10.77 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12"></path>
              </Pinterest>
            </a>
            <a
              href="https://www.instagram.com/kirstennoellew/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Kirsten Noelle Instagram"
              tabIndex={!isOpen ? '-1' : '0'}
            >
              <Instagram
                viewBox="0 0 512 512"
                role="img"
                aria-labelledby="headerInstagramID"
              >
                <title id="headerInstagramID">Instagram Icon</title>
                <g>
                  <path d="M256 109.3c47.8 0 53.4 0.2 72.3 1 17.4 0.8 26.9 3.7 33.2 6.2 8.4 3.2 14.3 7.1 20.6 13.4 6.3 6.3 10.1 12.2 13.4 20.6 2.5 6.3 5.4 15.8 6.2 33.2 0.9 18.9 1 24.5 1 72.3s-0.2 53.4-1 72.3c-0.8 17.4-3.7 26.9-6.2 33.2 -3.2 8.4-7.1 14.3-13.4 20.6 -6.3 6.3-12.2 10.1-20.6 13.4 -6.3 2.5-15.8 5.4-33.2 6.2 -18.9 0.9-24.5 1-72.3 1s-53.4-0.2-72.3-1c-17.4-0.8-26.9-3.7-33.2-6.2 -8.4-3.2-14.3-7.1-20.6-13.4 -6.3-6.3-10.1-12.2-13.4-20.6 -2.5-6.3-5.4-15.8-6.2-33.2 -0.9-18.9-1-24.5-1-72.3s0.2-53.4 1-72.3c0.8-17.4 3.7-26.9 6.2-33.2 3.2-8.4 7.1-14.3 13.4-20.6 6.3-6.3 12.2-10.1 20.6-13.4 6.3-2.5 15.8-5.4 33.2-6.2C202.6 109.5 208.2 109.3 256 109.3M256 77.1c-48.6 0-54.7 0.2-73.8 1.1 -19 0.9-32.1 3.9-43.4 8.3 -11.8 4.6-21.7 10.7-31.7 20.6 -9.9 9.9-16.1 19.9-20.6 31.7 -4.4 11.4-7.4 24.4-8.3 43.4 -0.9 19.1-1.1 25.2-1.1 73.8 0 48.6 0.2 54.7 1.1 73.8 0.9 19 3.9 32.1 8.3 43.4 4.6 11.8 10.7 21.7 20.6 31.7 9.9 9.9 19.9 16.1 31.7 20.6 11.4 4.4 24.4 7.4 43.4 8.3 19.1 0.9 25.2 1.1 73.8 1.1s54.7-0.2 73.8-1.1c19-0.9 32.1-3.9 43.4-8.3 11.8-4.6 21.7-10.7 31.7-20.6 9.9-9.9 16.1-19.9 20.6-31.7 4.4-11.4 7.4-24.4 8.3-43.4 0.9-19.1 1.1-25.2 1.1-73.8s-0.2-54.7-1.1-73.8c-0.9-19-3.9-32.1-8.3-43.4 -4.6-11.8-10.7-21.7-20.6-31.7 -9.9-9.9-19.9-16.1-31.7-20.6 -11.4-4.4-24.4-7.4-43.4-8.3C310.7 77.3 304.6 77.1 256 77.1L256 77.1z" />
                  <path d="M256 164.1c-50.7 0-91.9 41.1-91.9 91.9s41.1 91.9 91.9 91.9 91.9-41.1 91.9-91.9S306.7 164.1 256 164.1zM256 315.6c-32.9 0-59.6-26.7-59.6-59.6s26.7-59.6 59.6-59.6 59.6 26.7 59.6 59.6S288.9 315.6 256 315.6z" />
                  <circle cx="351.5" cy="160.5" r="21.5" />
                </g>
              </Instagram>
            </a>
          </Item>
        </List>
      </Nav>
    </div>
  )
}

export default Menu
