const theme = {
  colors: {
    base: '#1B221D', // Dark Gray/Black
    background: '#3B493F', // Green Gray
    secondary: 'rgba(51,60,57,.1)', // Medium Gray
    tertiary: '#F5F5F1', // Light Gray
    accent: '#D1DF2F', // Bright Yellow/Green
    border: '#E6E0D0', // Brown
    caption: '#616B65', // Medium Gray with a hint of blue/green
  },
  sizes: {
    maxWidth: '1200px',
  },
  responsive: {
    small: '40em',
    medium: '50em',
    large: '80em',
  },
  padding: {
    small: '4em',
    medium: '4em',
    large: '6em',
  },
}

export default theme
