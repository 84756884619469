import React, { useEffect } from 'react'
import styled, { ThemeProvider, StyleSheetManager } from 'styled-components'
import GlobalStyle from '../../styles/global'
import theme from '../../styles/theme'
import Menu from './Menu'
import Footer from './Footer'
import Transition from './Transition'

const Main = styled.div`
  width: 100%;
  padding: 56px 0 0 0;
  @media (min-width: ${(props) => props.theme.responsive.medium}) {
    padding: 0;
  }
`

const Skip = styled.a`
  padding: 0 1em;
  line-height: 57px;
  background: #2867cf;
  color: white;
  z-index: 101;
  position: fixed;
  top: -100%;
  &:hover {
    text-decoration: underline;
  }
  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`

const Layout = (props) => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab)
  }, [])

  return (
    <>
      <StyleSheetManager enableVendorPrefixes>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <>
            <Skip href="#content" id="skip-navigation">
              Skip to content
            </Skip>
            <Menu />
            <Transition {...props}>
              <Main id="content" role="main" key={props.location.pathname}>
                {props.children}
                <Footer />
              </Main>
            </Transition>
          </>
        </ThemeProvider>
      </StyleSheetManager>
    </>
  )
}

export default Layout
