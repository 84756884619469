import React from 'react'
import styled, { css } from 'styled-components'

const SVG = styled.svg`
  ${props =>
    props.small &&
    css`
      width: 68px;
      height: 56px;
    `};
  ${props =>
    props.white &&
    css`
      fill: white;
    `};
  ${props =>
    props.menu &&
    css`
      @media screen and (min-width: ${props => props.theme.responsive.medium}) {
        fill: white;
      }
    `};
`

const Logo = props => {
  return (
    <SVG
      viewBox="0 0 1861.8 834"
      {...props}
      role="img"
      aria-labelledby="logoID"
    >
      <title id="logoID">Kirsten Noelle Logo</title>
      <path
        d="M1321.2,198.6c9.3,13.8,15.2,23.2,24.3,37.1c1.5,2.3,3,4.6,4.6,6.9c4.8,7.2,11.4,13.6,16.3,20.6
	c3.4,4.8,18.8,23.3,22.1,28.1c4.5,6.6,1.9,12,2.1,21.2c0,0.9,0,2.3,0.1,3.5c0.1,1.5,1,2.8,2.4,3.3c1.2,0.4,2.4,0.8,3.2,1
	c12.9,4.1,16.3,3.8,34.8,6.4c3.1,0.5,6.3,0.9,9.4,1.4c1.2,0.2,2.4-0.7,2.6-1.9c0-0.3,0-0.6-0.1-1c-2.5-9.6-2.8-19.1-3.1-28.4
	c-0.1-1.8-0.1-3.6-0.2-5.4c-0.4-8.9-0.3-17.8-0.1-26.4c0.2-11,0.3-22.5-0.6-33.5c-0.4-4.4,0.3-12.5,0.1-16.1
	c-0.3-6.8,0.6-13.1,0.6-18.9v-2.3c0-4.2-0.1-8.3-0.1-12.5c-0.1-10.1-0.3-20.5,0.4-30.8c1.2-18.2,3.2-28.5,2.8-48.2
	c-0.1-4.4-0.1-8.8,0.1-13.2c0.2-4.1,0.2-8.1,0.2-12.2c0.1-10.7,0.1-21.7,2.7-32.7c0.5-2.2-0.7-4.6-2.1-4.9
	c-1.6-0.3-6.1-0.5-10.5-1.3c-3.9-0.7-10.4-1.1-12.1-1.4c-4.7-0.7-6.7-1.5-11.3-2.1s-14.3-2.4-13.2,1.9c0.8,3.1,0.8,3.8,1,4.3
	c2.8,11.6,2.6,18,3.3,29.1c0.3,5.7,0.2,11.3,0,16.7c-0.2,4.7-0.3,9.1-0.1,13.5c0.8,19.8,0.5,35-0.9,49.3
	c-1.2,12.3-2.4,28.4-1.3,44.4c0.1,3.5,0,7-0.5,10.5c-0.2,1.3-0.3,2.7-0.4,4l0.1,24.1c0,0.6-0.4,1-0.9,1c-0.4,0-0.7-0.2-0.9-0.5
	l-10.9-17.4l-2.4-3.9c-4.8-7.8-9.7-15.7-14.5-23.5c-11-17.7-22.3-35.9-33.1-54.1c-15.4-25.9-29.3-52.6-41.5-80.1
	c-4-9-6.8-14.4-5.2-26.9c0.1-1,0.1-2,0-3c-0.1-1.3-1.2-2.4-2.5-2.6c-2.6-0.4-7.2-1-8.7-1.2c-13.1-1.6-13.5-2.2-26.4-3.6l-2.1-0.2
	c-3.3-0.4-2.8-0.6-5.7-0.3c-1.1,0.1-2.3,0.3-3.4,0.6c-0.1,1.3-0.1,2.5,0.1,3.8c0.3,3.1,0.8,6.2,1.7,9.2c2.4,7.4,3.6,15.2,3.5,23
	l-0.4,35.4c-0.2,14,0.2,28.3,0.5,42.1c0.2,9.6,0.5,19.6,0.6,29.5c0,6.3,0,12.4-0.3,18.6c-0.3,4.5-0.8,8.9-1.3,13.2
	c-0.7,5.2-1.2,10.3-1.4,15.5l-0.1,3.2c-0.3,14.1-0.5,30.6-1.8,45.7c-0.8,10.3-2.3,24.2-4.4,31c-0.4,1.1,0.2,2.3,1.3,2.7
	c0.1,0,0.3,0.1,0.4,0.1c1.8,0.2,3.2,0.3,4.1,0.4c17.9,1.4,12.7,1.7,30.6,3.1l7.3,0.6c1.3,0.1,2.5-0.9,2.6-2.2c0-0.1,0-0.3,0-0.4
	c-0.2-2.4-0.5-5.6-0.7-6.4c-0.8-3.3-0.9-3.4-0.4-7.8c1.1-9.4,0.9-18.9,0.6-28.8c-0.2-8-0.4-16.3,0.2-24.6l0.4-5.7
	c0.8-11,1.6-22.3,0.6-33.2c-0.7-7.7,0.3-52.5,1.9-53.2c1.9-0.8,6.2,13.3,6.6,14.4c1.4,3.8,4.2,8.6,6.1,12.2s4.2,7.2,6,11
	c2.7,5.5,5.6,8.4,8.5,13.8c1.1,2,2.5,3.8,4,5.5C1318.2,191.5,1319.2,195.6,1321.2,198.6z"
      />
      <path
        d="M1175.4,741l2.5-1c3.4-1.7,7.1-2.6,10.9-2.9c8.7-0.3,17.7-2.1,28.3-5.7c19.3-6.5,38.9-12,58.7-16.4
	c8.3-1.9,16.3-4.1,23.9-6.6l0.8-0.3c-0.1-0.3-0.2-0.8-0.3-1.3c-0.7-2.6-1.2-5.2-1.3-7.8c-0.2-5.9-0.3-11.8-0.4-17.6
	c-0.1-4.5-0.2-8.9-0.3-13.4c0-2-0.2-3.9-0.5-5.8c-0.1-0.5-0.3-1-0.5-1.5c-0.5,0.2-0.9,0.3-1.4,0.6c-5.9,3-12,5.3-18.4,7
	c-4.5,1.2-9.1,2.3-13.7,3.5c-7.4,1.8-15,3.8-22.4,5.9c-7.5,2.2-15.2,3.5-23,4c-10,0.6-12.8-5.7-13.5-11c-0.8-6.7-1-13.3-1.1-19.7
	c-0.1-4.9-0.2-9.5-0.6-14.1c-1.1-11.4,5-11.1,7.7-12.7c4-2.3,7.2-2.6,10.1-3.8c2.2-0.9,6.3-0.8,7.5-1.5c4.9-2.6,8.8-2.5,13.7-3.8
	s10-3.3,15.4-3.3c1.5-0.1,1.9-1,2.1-2.1c0.1-0.9,0.1-1.9,0-2.8c0-0.8-0.1-1.5-0.1-2.3c-0.1-4.1-0.3-8.3-0.5-12.4
	c-0.5-9.3-1-18.9,0.2-28.6c0-0.4-0.2-0.7-0.6-0.7c-0.1,0-0.2,0-0.3,0c-14.1,5-28.2,10.2-42.5,15.6c-2.7,1-11.7,3.4-14,1.8
	s-1.7-9.4-1.8-11.8c-0.3-7.4-0.5-14.8-0.6-22.2c-0.1-4.2-0.2-8.4-0.3-12.5c-0.3-10.8,1.5-13.5,11.2-17.2l0.4-0.1
	c13.6-5.2,27.7-8.4,41.4-11.6c7-1.6,14.1-3.2,21.1-5.1c4.4-1.2,10-3,16.1-1.7c4.8,1.1,5.7-1.6,5.6-2.8c-0.2-5.6,0-11.1,0.6-16.7
	c0.1-1.5,0.3-3.1,0.4-4.6c-0.1-3.5,0.1-7,0.2-10.3c0.2-6.2,0.4-12.1-0.6-17.7c-0.3-1.6-0.9-3.7-3.7-2.4l-4.5,1.9
	c-12,5-24.4,10.2-37.6,13.5c-9.7,2.4-19.4,5.3-28.9,8c-5,1.4-9.9,2.9-14.9,4.3l-6.3,1.8c-14.6,4.2-29.7,8.5-45.6,9.9
	c-2,0.3-4,0.9-5.9,1.7c-1.5,0.6-2.2,1.2-2.2,1.5c0,0.9,0.3,1.8,0.8,2.5c3.6,6.1,5.3,12.6,6.8,19.2c1.8,7.7,2.1,15.3,2.5,22.7
	c0.1,2.4,0.2,4.8,0.4,7.2c0.7,10.2,1.3,21.1,0.6,32.3c-0.1,3.7,0,7.4,0.3,11.1c0.4,4.8,0.5,9.6,0.2,14.5c-0.8,9.6-0.8,19.2,0,28.7
	c2,27.2,3,54.3,3,80.3c0,10.8-0.4,23.1-2.8,35.6c-0.5,2.6,1.1,5.2,3.7,5.7c0.9,0.2,1.9,0.1,2.7-0.2
	C1167.9,744.3,1171.7,742.7,1175.4,741z"
      />
      <path
        d="M1366,673.2c0,4.9-1.5,12.2-2.5,16.4c-0.3,1.3,0.6,2.7,1.9,3c0.2,0.1,0.5,0.1,0.7,0l4.3-0.4c9.9-0.5,12.4-1.8,22.6-3.8
	c7.1-1.4,14.4-2.8,21.8-3.8c6.7-0.8,13.6-2.1,20.2-3.3c9.7-1.8,19.6-3.6,29.8-4.3c7.2-0.7,14.3-1.9,21.2-3.8c3.9-1,7.9-1.7,11.9-2.1
	c4.5-0.4,5.2-1.4,5.2-5.4c0-2.7-0.2-5.4-0.4-8.1c-0.5-6.8-0.6-13.5-0.2-20.3c-0.4-3.8-0.4-7.6,0.1-11.4c0.2-1.9-1.1-5.3-5.1-3.3
	c-6.7,3.2-13.7,5.7-21,7.3c-5.3,1.3-10.8,2.4-16,3.5c-10.3,2.1-20,4.1-29.5,7.3c-2.4,0.8-8,2.7-12.6-0.5s-4.7-9.1-4.7-11.9
	c-0.1-8.5-0.4-17.3-0.8-25.7c-0.7-18.9-1.4-38.4-0.1-57.8c0.4-6,0.7-12.3,0.9-20.6c0.2-6.7,0-13.5-0.2-20.2
	c-0.3-10.8-0.7-21.9,0.6-33.2c0.7-7,0.9-14.1,0.8-21.2c0-9.5,0-19.2,1.8-29c0.4-2.2,1.7-7.8-1.3-7c-2.8,0.7-5.8,0.9-8.1,1.6
	c-8.2,2.4-16.4,4.7-24.6,7.1c-6.1,1.7-12.3,3.5-18.4,5.2c-1.7,0.5-3.5,1.5-3.1,4.7l0.5,4.8c1.6,15.1,3.2,30.7,1.9,46.5
	c-0.2,3.2-0.2,6.4,0.1,9.6l0.1,2.2c0.2,5.2,0.5,10.4,0.8,15.6c0.6,10.1,1.1,20.6,1.3,30.9c0.1,6.1-0.1,12.1-0.4,18
	c-0.3,6.6-0.5,12.9-0.3,19.2c0.8,21.9,0.7,44.2,0.6,65.7C1366,654.3,1365.9,663.7,1366,673.2z"
      />
      <path
        d="M464.6,331.2c5.2-1.2,8.3-1,15.4,6.5c3.3,3.5,12,15.7,15.3,19.2c7.6,8,15.4,16.3,23.3,24.2c4.2,4.2,7.7,10,10.4,16.4
	c1.1,2.7,4.3,4,7,2.8c0,0,0.1,0,0.1-0.1c12-5.6,25.1-11.1,36.6-15.1c1.8-0.6,2.9-0.8,4.3-2.5c1.9-2.3-6.2-4.7-9.4-7.5
	c-15.8-14-31.2-30.6-48.7-52.1c-3.3-4-8.8-8.4-8-10.2c0.9-2.2,7.7-5.3,11.2-8c5.4-4,10.6-9,15.8-14c1.1-1,2.2-2.1,3.3-3.1
	c12.2-11.3,17.3-23.4,22.2-43.6c1-5,1.4-10.1,1.2-15.1c-0.1-3.5,0.2-6,0.4-9.9s-0.6-7.9-0.7-11.5c-0.1-4.6-0.8-9.2-2.3-13.5l0,0
	c-4-11.4-6-15-14.6-24.1c-1.7-1.7-3.6-3.2-5.6-4.6c-3.2-2.1-6.6-3.9-10.2-5.4c-18.5-7.1-28.7-8.2-49.9-5c-14.5,2.3-28.7,6.3-42.2,12
	c-4,1.6-8,3.2-12,4.7c-4.2,1.6-8.4,3.3-12.6,5c-9.8,3.9-15.5,7.1-26.2,9.8c-1,0.2-1.8,0.5-2.4,0.6c0.1,0.6,0.2,1.3,0.4,2.2
	c1.5,7.3,3,14.8,3.1,23c0.1,20.8-0.7,41.9-1.5,62.2c-0.4,10.4-0.8,20.7-1.1,31.1c-0.1,2.5,0,5,0.2,7.6c0.2,5.1,0.5,10.5-0.6,16
	s-1.2,11-0.5,18.6c1.9,21.3,0.4,42.6-1.2,63.1l-0.4,5.8c-0.7,10.2-1.5,20.7-2,31c-0.1,1.2,0,2.1,0,2.8c1.2-0.1,2.3-0.3,3.5-0.5
	c9.4-1.8,14-3.5,23.5-6.4c7.1-2.1,14.5-4.3,22-6.1c1.2-0.3,2.3-0.6,3.5-1c-0.3-1-0.6-2.1-1-3.1c-6.2-16.3-6-32.8-5.9-48.8
	c0-2.6,0-5.2,0-7.7c0-6.1-0.4-20.9,0.1-26.7c0.7-9.2,6.2-12.2,10.7-13.1c5.6-1.1,11.4-2.5,16.9-3.8
	C458.8,332.6,461.7,331.9,464.6,331.2z M441.2,297.2c-0.5,0-0.9,0-1.2,0h-3.6l-2.4-2.9c-2.9-3.9-2.9-8-2.8-10.4v-1
	c-0.2-13.4,0.2-27,0.6-40.1c0.2-5.6,0.3-11.2,0.5-16.8c0.2-8.8,4.3-14.7,12.3-17.6c9.9-3.7,20-6.9,30.3-9.6
	c7.5-1.8,15.6-1.9,24.7-0.3c14.2,2.5,23.3,16.8,21.2,33.4c-2.2,17.2-10.4,31.2-25.1,42.7c-14.2,11.2-30.8,18.3-52,22.5
	C442.7,297.1,441.9,297.2,441.2,297.2z"
      />
      <path
        d="M1728.6,650.3c2.5,0.7,5,1.1,7.5,1.3c15.9,0.4,29.7,2.2,42.3,5.6c10,2.7,20.7,5.8,32.7,9.7c3.8,1.2,7.6,2.1,11.7,3.1
	c7.8,1.8,16.6,3.4,23.8,8.4c2.7,1.8,2.6-1.8,2.7-3.1c0.9-10.5,1.6-21.1,2.4-31.6l0.2-3.1c0.2-1.2-0.7-2.2-1.8-2.4
	c-0.2,0-0.3,0-0.5,0c-17.7-0.5-34.6-5.1-50.8-9.4c-5.8-1.5-11.9-3.2-17.8-4.5c-6.3-1.5-10-6.1-10.1-12.7c-0.2-6.9-0.4-13.8-0.6-20.7
	l-0.3-10.2c-0.1-2-0.2-4-0.2-6c-0.2-5-0.5-10.2-0.3-15.5s1.8-9.3,5-11.8c4.7-3.9,10.9-2.8,12.9-2.5c13.2,2.3,28.5,5,45.3,11.9
	c1.6,0.9,2.4-0.9,2.3-2.2c-0.3-4.2,0.1-5.6,0-10c0-1.8,0-3.7,0-5.5c-0.5-4.2-0.7-8.4-0.6-12.7c0-2.8,0-5.5-0.2-8
	c-0.1-1.3-1.3-2.3-2.6-2.2c-6.4,0.3-12.9-0.2-19.2-1.5l-8.1-1.5c-6.8-1.3-13.6-2.6-20.4-3.8c-9.3-1.6-14.8-4.9-14.8-17
	c0-7.5-0.1-14.3,0.2-21.2c0.4-11.5,0.9-23.8,1.8-35.8c0.2-2.7,0.6-8.3,5.1-11.3s9.5-1.1,11.9-0.3c3.4,1.1,7,1.8,10.5,2.3
	c2.5,0.4,4.9,0.8,7.3,1.3c14.4,3.2,29.1,8.2,46.1,15.6c1.4,0.6,3.3,2.6,5.1,3.3c1.3,0.5,2.7-0.2,3.2-1.5c0.1-0.2,0.1-0.4,0.1-0.7
	c0.2-3.3,0.2-7.8,0.4-11.1c0.2-4.3,0.7-8.6,0.9-12.9c0.2-4.1,0.5-7.4-1.4-8.7c-4.3-3-13.7-4.1-17-5c-4.8-1.3-9.6-2.6-14.4-3.9
	c-15.4-4.2-31.4-8.6-47.2-12.1c-17.1-3.8-34.7-5-50.2-7.5c-3.5-0.6-4.9,1.1-3.7,6.4c1.2,5,2.1,10,2.6,15.1
	c2.4,25.6,1.8,51.5,1.3,76.5c-0.1,6.6-0.3,13.2-0.4,19.8c-0.1,7.6-0.2,15.2-0.4,22.7c-0.4,21.8-0.9,44.4-0.2,66.5
	c0.6,19.2-0.1,38.5-6.1,58.6C1724,650.3,1727.7,650.1,1728.6,650.3z"
      />
      <path
        d="M1542.2,602.3c-0.4,7.3-0.9,14.9-0.9,22.2c0,9.5,0.2,19.5-1.6,29.5c-0.7,3.6-0.1,7.5,4.2,6.5c18.2-4.3,37.2-8.1,56.7-8.4
	l1-0.2c26.5-4.9,52.4-3.7,74.2-1.8c1.4-15.6,0.3-28.1-0.7-39.6c-0.5-6.2-1.1-7.8-6.4-6.4c-13.4,3.7-26.6,4.8-39.1,5.5
	c-2.3,0.1-4.7,0.2-7,0.3c-6.3,0.2-12.5,0.7-18.7,1.5c-2.3,0.4-7.1,1.1-10.9-1.8c-3.4-2.6-4.2-6.5-4.6-10c-0.4-4-0.4-7.9-0.4-11.7
	c0-2.6,0-5.2-0.2-7.8c-0.3-7.2-0.2-14.5,0.3-21.7c0.3-6.1,0.6-11.8,0.4-17.5l-0.6-20.4c-0.3-8.7-0.5-17.3-0.8-26
	c-0.6-21.2,0.3-42.5,1.1-63.2l0.5-12c0.4-11.1,0.9-22.3,4.8-33.4c0.3-0.7,0.5-1.5,0.8-2.2c0.1-0.3-0.5-0.8-0.9-0.8
	c-0.8,0-1.6,0-2.4,0.1l-1.7,0.1c-10.5,0.4-20.8,2.5-31.6,4.7c-5.5,1.1-11.2,2.3-17,3.2c-0.3,0.1-0.9,0.2-1.3,0.3
	c-1.4,0.3-1.7,1-0.5,5.1c1.6,6.2,2.6,12.5,2.8,18.8c0.1,6.3,0.3,12.6,0.4,18.9c0.2,8,0.4,15.9,0.5,23.8c0.1,6.8,0.3,13.6,0.5,20.2
	c0.5,15,0.9,29.1-0.1,40.6c0,8.1,0,15.5,0.1,22.6c0.1,15.8,0.2,29.4-0.2,43.2C1542.9,590.6,1542.6,596.6,1542.2,602.3z"
      />
      <path
        d="M937,452.3c10.1-6.1,20.8-10.3,30-13.7l2.3-0.9c10-3.8,13.9-6.5,25.5-6.7c7.5-0.1,9.4-0.9,9.4-4.2c0-9.9-0.6-20.1-3.2-29.7
	c-1.6-5.8-3.2-5.7-5.9-5.8c-6.6,0-13.5,0.4-19.2,3.1c-4.7,2.1-9.5,3.6-14.5,4.5c-6.8,1.6-13.4,3.8-19.9,6.3
	c-4.7,1.9-9.6,3.3-14.7,4.3c-0.4,0.2-6.2,2.8-6.5,3l-4.7,2.5c-3.3,2.3-4,2.8-7.4,5.1c-11.5,7.9-23.5,16.1-33.8,25.5
	c-19,17.3-35.2,37.4-48,59.7c-1,1.6-2.1,3.2-3.2,4.7c-1.2,1.4-2.2,3-2.9,4.7c-1.5,4.6-3.2,9-4.8,13.3c-2.6,6.7-5,13.1-6.5,19.7
	c-0.2,1-0.4,2-0.5,3.1c-0.4,3.6-1.4,7-3,10.2c-2.2,4-2.8,8.9-3.3,14.2l-0.1,0.6c-1.1,9.7-1.5,19.7-1.9,29.4
	c-0.3,6.3-0.5,12.8-1,19.3c-1.6,24.2-1.2,48.5,1.2,72.6c0.6,5.8-0.6,8.2,0.1,14c0.4,3.6,0.4,10.4,0.8,14c0.6,6.2,0.9,18.1-1.2,19.2
	c-4.3,2.4-11.8-8-14.7-11.9c-2.3-2.9-5-5.6-8-7.8c-2.5-1.7-10.7-14.3-13-16.6c-11.9-11.8-8.5-9.8-15.6-19.8
	c-8.5-12-18-22.6-26.8-34.3c-8.3-11-17.2-23.1-25.4-34.5L687,606c-13.1-18.3-28.4-38.5-41.6-57.2l-1.9-2.7
	c-8.9-12.5-20.7-25-23.4-42.3c-0.3-2.2-2.4-3.8-4.6-3.6c-2.1,0.2-4.3,0.2-6.4-0.2c-7.5-1.1-15.2-1.1-23.2-1
	c-5.9,0.1-16,0.1-22.2-0.3c-6.5-0.5-8,0.2-8.3,0.4c0,0-0.9,1.8,0.1,10c0.4,3.5,0.9,6.9,1.3,10.4c1.1,8.3,2.2,16.8,2.8,25.4
	c1.8,25.3,0.8,50.8-0.1,75.5l-0.2,5.3c-0.2,6-0.5,12.1-0.8,18.1c-0.7,13.4-1.4,27.2-1,40.7c0.4,11.7,0.3,23.5,0.2,34.9
	c-0.1,16-0.2,32.5,1,48.6c0.2,3.9,0.3,7.7,0.1,11.6c-0.2,3.9-0.2,7.9,0.2,11.8c1.8,15,1.2,28.6-1.8,41.5c-0.1,0.4-0.2,0.7-0.2,1
	c0.5,0,1,0.1,1.7,0.1c4.8,0.2,9.7,0.1,14.5,0.1V834h14.9c2.7,0,5.4,0,8.1,0h3.7c3.1,0.1,6.2,0,9.3-0.3h0.2c0-0.1,0-0.1-0.1-0.2
	c-3.1-8.8-4.6-16.2-4.7-23.5c-0.3-14.3-0.6-28.6-0.9-42.9l-0.2-9c-0.1-3.8-0.1-7.5-0.1-11.3c0-7.5-0.1-15.3-0.5-22.9
	c-0.8-14.4-1.3-27.3-1.5-39.4c0-8.2,0.5-16.5,1.5-24.6c0.7-7,1.4-13.5,1.4-20.2c0-3.2,0-6.4-0.1-9.6c-0.2-7.3-0.4-14.8,0.8-22.5
	l0.1-0.5c0.7-6.9,0.2-22.1,3.4-22.8c5.7-1.2,13.6,15.7,14.7,17l0.3,0.3c0.5,0.5,1,1,1.4,1.5c1.4,1.4,2.8,2.9,4,4.5
	c2.8,3.8,5.6,7.6,8.4,11.4c7.2,9.8,14.8,19.9,21.6,30.4c17.7,27.1,38.1,52.8,57.9,77.6c8,10.1,16.4,20.2,24.4,30.1
	c13.9,17,28.3,34.5,41.8,52.4c4,5.2,7.1,11,9.2,17.2c0,0.1,0.1,0.3,0.2,0.5c0.6,1.8,2.3,3,4.1,3.1h1c8.2,0.3,16.4,0.1,24.6-0.4
	c7.3-0.4,15-0.8,22.6-0.5h0.5c-4.9-13.3-7.1-27.5-6.5-41.7c0.9-18.7,0.6-37.7,0.3-56.2c-0.2-14.4-0.5-29.3-0.1-44
	c0.2-8.3,0.1-16.7,0.1-24.9c-0.1-11.3-0.1-22.9,0.4-34.5c1-20.3,4.6-40.4,10.8-59.8l1.8-5.5c5.3-16.8,10.8-34.1,21.5-49.9
	C890.6,487.5,911.4,467.6,937,452.3z"
      />
      <path
        d="M74.3,344.5c6,9.5,17.7,30.8,23.8,42.4c12.1,22.9,23.2,43.4,36,62.6c5.6,8.5,11.2,17.1,16.6,25.5c11,17.2,22.4,35,34.8,51.4
	c1.7,2.3,3.4,4.6,5.1,6.8c6.8,9.2,13.2,17.9,21.1,25.6c6.8,6.7,13.9,13.1,21.7,19.5c10.2,8.5,22.2,15.6,38.8,23.1
	c13.8,6.3,28.6,11.1,42.9,15.8c4.3,1.4,8.8,2.7,13.2,4c3.9,1.1,7.9,2.3,11.9,3.6c2.5,0.8,5.3-0.6,6.1-3.1c0.1-0.4,0.2-0.9,0.2-1.3
	c0.1-4,0.3-7.9,0.5-11.8c0.4-8,0.8-16.2,0.6-24.2c0-1.5-1.3-2.7-2.8-2.7c-10-0.1-20.3-2.4-31.5-7c-13.3-5.5-26.1-12.1-38.3-19.8
	c-21.4-13.4-40.2-30.6-55.4-50.8c-11.2-15.1-21-30.8-30.8-46.9c-5.6-9.1-11.2-18.2-16.8-27.3c-16.7-27-34-54.9-49.1-83.7
	c-2.6-5-5.7-9.7-8.9-14.7c-3.6-5.3-7-10.9-10-16.6c-3.4-6.6-4.4-12.5,0.1-18.3c1.5-2,3.8-4.6,5.4-6.4c2-2.1,3.8-4.3,5.4-6.6
	c10.7-17,23.9-32.1,35.8-47.6l4.9-6.3c2.8-3.6,5.9-7.2,8.8-10.5c3.6-4.2,7.1-8.2,10.2-12.5c4.2-5.6,8.6-10.9,13.3-16
	c3.6-4,7-7.9,10.2-11.9c5.1-6.6,10.1-13.6,14.8-20.4c13.2-19.3,23-26.4,21.9-29.4c-1-3.1-4-2.5-6.4-2.4c-10.8,0.3-21.4,1.9-31.8,1.4
	c-6.1-0.3-8.6-0.2-14.8-0.4c-1.4,0-5.5-0.5-6-0.4c-0.9-0.1-2-0.3-2.4,1.1c-0.3,1,0.6,3.9,0.4,5.2c-0.5,3.7-1.2,8.3-4.6,12.3
	c-2.3,2.8-4.4,5.7-6.3,8.8c-2,3.3-4.3,6.5-6.7,9.6c-3.5,4.2-7,8.3-10.5,12.5c-6.2,7.4-12.7,15-18.7,22.6
	c-13.4,17.3-27.6,36.1-45.9,61c-3.6,4.7-7.5,9.2-11.7,13.4c-2.4,2.5-4.6,4.9-6.8,7.4l-9.3,7.7c-1.4,1.1-3.4,0.9-4.5-0.4
	c-0.5-0.6-0.7-1.3-0.7-2.1c0.1-6.5,0.2-15.4,0.3-16.9c0-0.4,0-0.8,0-1.2c0-3.4,0-6.8,0.1-10.2c0.1-4.9,0.2-9.5,0-14.1
	c-0.7-13.8,0.4-27.5,1.4-40.7c0.5-6.9,1.1-14,1.3-21v-0.7c0.5-13,1-26.4,4.9-39.7c1-3.8,2.2-7.6,3.6-11.3c1.8-4.2-10.6-3.1-16.3-3.2
	c-12-0.2-23.9-0.4-35.8-0.6c-1.6-0.1-3.3,0-4.9,0.1c-1.7,0.2-2.8,1.5-2.7,3c0,0.1,0,0.2,0,0.3c0.3,1.6,0.8,3.1,1.2,4.7
	c4.1,13,5.9,26.6,5.4,40.2v1.4c-1.2,34.3-2.4,69.7-1.1,104.4c0.2,4.8,0.4,9.7,0.7,14.5c0.6,12.4,1.3,25.2,0.8,38
	c-0.2,6.1-0.4,12.3-0.6,18.4c-0.4,13.1-0.8,26.7-1.5,40c-0.9,15.9-0.8,32.1-0.7,47.8c0.1,17.6,0.2,35.7-1,53.7
	C3,497.9-0.4,507,2.7,507c0.5,0,3.7,0,4.2,0l8.6,0.5c9.4,0.4,18.8,0.7,28.3,1c2,0,4.1,0,6.4-0.1c2.1-0.1,3.7-1.9,3.5-4
	c0-0.2,0-0.4-0.1-0.6c-2.6-11.3-5.1-23.6-5.9-34.4c-0.2-2.6-0.5-5.3-0.9-7.9c-3.7-23.6-3.2-46.6-2.1-72.3c0.3-8.3,1.7-18.2,1.3-25.5
	c-0.3-7.7,0.5-15.6,4.7-20c3.2-3.6,7.1-6.6,11.5-8.8C69.6,330.9,72.8,342.2,74.3,344.5z"
      />
      <path
        d="M1105.5,595.8c-0.6-3-1.1-5.9-1.6-8.9c-2.6-14.7-10.6-28.4-24.5-41.8c-10.9-10.8-23.4-19.9-37.1-27
	c-9.8-5-20.5-6.7-31.6-8.2c-2.1-0.3-4.2-0.7-6.3-1c-3.4-0.8-7-1.2-10.5-1.2c-6.7,0.1-13.7,2-20.3,4.1c-12,3.7-22.6,11.1-32.3,18.3
	c-1.3,0.9-2.4,2.1-3.2,3.4c-3.6,7.6-8.4,14-13,20.2c-1.3,1.7-2.5,3.3-3.7,5c-4.2,5.8-7.8,12-10.9,18.4
	c-7.9,16.5-10.8,34.2-13.9,52.9l-1.4,8.3c-0.9,5-1.7,9.7-0.8,14l0.1,0.7l0.8,6.3c2.7,22.8,5.2,44.3,16.2,63.7c1,1.9,1.6,4,1.7,6.2
	c0.6,5.9,4,10.5,7.9,16l1.1,1.5c3.4,4.4,7.1,8.5,11.2,12.3l1.7,1.7c8,7.8,15.6,15.2,26,17.4c10.1,2.1,19,4.3,27.2,6.9
	c5.6,1.9,11.8,1.6,17.2-0.8c6-2.5,12.2-4.6,18.5-6.3c2.1-0.6,4.3-1.2,6.4-1.9l2.4-0.7c6-1.8,11.6-3.5,16.1-7
	c13.8-10.7,23.9-24.5,34.6-39.1l3.8-5.1c1-1.3,1-1.8,0.9-2.1c-0.1-2.3,0.4-4.6,1.2-6.7c0.1-0.3,0.2-0.6,0.3-1
	c1-5.3,3.3-9.8,5.4-13.8c1-1.9,1.9-3.7,2.7-5.5c1.5-3.9,2.6-7.9,3.3-11.9c0.9-4.8,2.2-9.5,4-14.1c2.5-6,3.4-12,4.3-19l0.3-2
	C1112,630.7,1108.9,613.8,1105.5,595.8z M1054.2,692.7c-5.6,18.1-17.7,30-28.9,39.2c-5.2,4.3-10.9,6.5-16.8,6.5
	c-1.4,0-2.7-0.1-4.1-0.3c-2.4-0.4-4.9-0.6-7.5-0.8c-2-0.2-3.9-0.3-5.8-0.5c-5.1-0.5-9.9-2.7-13.7-6.1c-29.3-25.7-40-59.6-32.8-103.5
	c3.4-21.4,11.8-41.6,24.6-59c1.1-1.4,2.2-2.8,3.4-4.1c6.1-6,8.1-7.5,15.3-10.2c5.1-1.7,10.5-2.4,15.9-2c0.8,0.1,1.7,0.1,2.5,0.2
	c23.1-2,35.2,13.1,43.2,23.1c9.4,11.7,11.8,26,13.9,38.6l0.4,2.3C1068.4,643.2,1061.6,668.8,1054.2,692.7z"
      />
      <path
        d="M275.6,428c-0.2,11,0.2,17.9-2.9,29.4c-0.2,0.9-1.4,4.9-0.6,5.6c1,0.8,4.4,0.5,4.6,0.5c2.9-0.5,5.9-0.6,8.9-0.5
	c3.4,0.1,6.8-0.1,10.2-0.4c4.1-0.5,8.3-0.6,12.5-0.5c1.6,0,3.1,0,4.7,0c3.4,0,5.8-0.8,6.8-2.1s1.3-4,0.6-7.4
	c-2.4-11.8-3.7-23.8-3.6-35.9v-1.5c0.1-20.8,0.1-42.3,1.6-63.5c0.5-7.1,0.5-14.1,0.4-21.5c0-3,0-5.9,0-8.9c0-3.7,0.2-7.4,0.3-11
	c0.2-5.1,0.4-10,0.3-14.9c-0.1-4.3-0.4-8.6-0.7-12.9c-0.3-4.6-0.7-9.4-0.8-14.2c-0.2-8.2,0.2-16.4,0.6-24.3c0.2-3.3,0.3-6.6,0.4-9.9
	c0.3-8.4,1.4-16.8,2.4-24.8c0.5-4,1-7.9,1.4-11.9c0.2-0.8,0.1-1.6-0.2-2.4c-0.8-0.3-1.6-0.4-2.5-0.4c-1,0-1.9,0.1-2.9,0.2
	c-2.4,0.3-4.8,0.3-7.1,0.1c-10-1.2-18.4-0.7-28.9,0.7c-1.6,0.3-3.2,0.9-4.7,1.5c-1.7,0.7-2.5,2.6-1.8,4.3c0,0.1,0.1,0.1,0.1,0.2
	c2,4.2,3.8,8.9,3.5,14.7c-0.5,10.5-0.5,21.2-0.4,31.7c0,13.5,0.1,27.4-1,41.3c-0.3,3.3-0.5,6.6-0.8,9.9c-1.1,12.6-2.2,25.7-1.9,38.4
	c0.2,8.4,0.8,16.9,1.3,25.1c1.1,16.3,2.2,33.2,0.9,50.2C275.7,417.8,275.7,422.7,275.6,428z"
      />
      <path
        d="M630.1,104.9c-10.8,11-16.7,32.5-13.5,48.4c0.6,3.1,1.1,6.2,1.5,9.2c0.6,3.9,2.2,9.8,3.1,13.3c3.2,12.7,14.7,20.6,26.3,29.7
	c6.7,5.2,14,9.7,21.8,14.4c3,1.8,6,3.7,9,5.5c8.5,5.3,16.6,10.7,23.8,17.9c11.3,11.2,16.9,24.2,16.5,38.6
	c-0.3,12.5-5,21.9-13.7,27.3c-12.7,7.9-25.2,9-37.2,3.3c-11-5.2-18.7-16.1-20.8-27.8c-0.6-3.1,0.1-6.3,0.7-9.5c0.4-2.3-2.9-3-5-2.6
	c-7.9,1.5-16,2.3-24.1,2.4c-3,0.1-6.1,0.4-9,0.9c-1.1,0.2-4.5,0.2-4.8,1.1c-0.8,2.4,2,4.1,2.5,11.8c1.2,16.4,6.7,29,17,39.3
	c20.1,20,37.3,28,57.8,26.9c1.2-0.1,2.5-0.1,3.8-0.1c1.6,0,3.3,0,5.2,0.1c9,0.5,17.9-1.8,27.1-7.1c2.3-1.3,4.7-2.6,7.1-3.9
	c10.6-5.4,18-11.5,23.2-19.5c3.1-4.9,6-10,8.6-15.2c7.4-14.4,7.2-29.9,5.9-43.1c-0.2-1.1-0.6-2.3-1.1-3.3c-0.2-0.6-0.5-1.2-0.7-1.7
	c-9.4-25.1-28.6-42.8-44.3-55.3l-2.5-2c-4.9-4-9.5-7.7-14.7-10.3c-12.2-6-22-14.8-31.6-23.2c-4.1-3.7-7.2-8.4-9-13.6
	c-5.6-16-1.1-28.9,12-39.7c5.4-4.4,11.8-6.4,18-5.6s12.4,0.2,16.5,6.1c3.2,4.5,5.1,9.8,5.3,15.3c0.1,1.7-0.7,3.7-1,5.4
	c-0.1,0.7,1.1,0.5,1.2,0.4c4.7-0.7,9.5-0.8,14.2-0.4c1.7,0.1,3.2,0.2,4.7,0.2h0.6c6.6,0.5,13.3,0.3,19.9-0.5c0.4,0,0.8-0.1,1.2-0.2
	c1.8-0.7-1.5-7.7-2.2-11c-1.7-9.1-4.7-18-8.9-26.3c-1.5-3-1.8-3.7-4.1-6.5c-1.1-1.5-2.4-2.9-3.7-4.2c-1.5-0.9-5.2-3.5-6.7-4.5
	c-11.5-8.2-25.3-12.3-39.4-11.8C662.4,74.7,644.7,90,630.1,104.9z"
      />
      <path
        d="M1165.3,10.2c-1.5-0.1-3.1-0.3-4.6-0.8c-3.1-0.8-5.6-0.6-8.5-1.1c-21.6-3.5-44-4.6-65.7-5.6c-11.8-0.6-24-1.2-36-2.1
	c-9.4-1.1-15.5-0.3-26.9-0.3c-4.2,0-12.1,1.8-16.4,2.5c-2.9,0.5-1.8,6.6-0.7,9.6c4.9,13.4,6.2,27.8,7.3,39.3
	c0.8,9.8,1,19.6,0.7,29.4c0,4.3-0.1,8.6,0,12.9c0.1,4.9-0.2,9.8-0.5,14.5c-0.5,7.9-0.9,15.3,0.2,22.6l0.1,0.8
	c0.6,3.9,1.2,8.2,0.2,12.7c-2.9,12.5-1.5,25.6-0.1,38.3c0.1,1.1,0.3,2.2,0.4,3.3c0.6,4,0.9,8.1,0.9,12.2c-0.1,3.3-0.1,6.7-0.2,10
	c-0.3,19.2-0.6,39-5.3,58.4c-0.7,2.6,0.9,5.3,3.6,5.9c0.6,0.2,1.3,0.2,2,0.1c2.1-0.4,4.2-0.6,6.3-0.6c9.7,0.6,19.8,0.5,29.6,0.4
	c3.8,0,7.6-0.1,11.5-0.1c8.8-0.1,17.6,0.3,26.4,1.1c6.7,0.7,13.6,1,20.3,1.4c12.5,0.6,25.4,1.3,38.2,4.1c7.1,1.6,14.2,3.1,21.2,4.6
	c2.3,0.5,3.9,0,4.5-0.5c0.5-0.7,0.7-1.6,0.6-2.5c-0.4-5.1-0.2-10.2,0.4-15.2c0.5-5.5,1-10.8-0.1-15.6c-0.5-2.7-0.4-5.5,0.4-8.1
	c0.2-0.5,0.3-1,0.3-1.6c-0.1-0.1-0.2-0.2-0.4-0.3c-2.1-1.6-3.2-1.8-4.9-1.6c-7,0.9-14.1,1.2-21.2,1c-7.3-0.4-14.5-1.5-21.4-2.5
	c-8.7-1.3-16.9-2.5-25.2-2.5c-6.6-0.2-13.2-0.9-19.7-2.2l-4.6-0.8c-0.8,0-1.6,0-2.4,0.1c-2.8,0.5-5.7,0.3-8.5-0.4
	c-7.9-2.4-14.8-5.6-11.8-18.3c1.2-5.4,1.4-10.9,0.7-16.4c-0.4-3.8-0.4-7.6-0.1-11.4c0.6-8.2,7.3-14.6,15.6-14.6c2.3,0,4.5,0,6.8,0
	c4.1,0,8.1,0,12.2-0.1c9.5-0.3,18.8,0.6,27.9,1.4c2.5,0.2,5.1,0.1,7.6,0.7c3.2,0.7,4.7-0.3,4.6-2.6c0-2.7-0.2-5.4-0.7-8l-0.2-0.8
	c-0.9-2.9-1.2-5.9-0.9-8.9c0.6-4.8-1.3-9.8-1-15.1c0.5-8.1-1-11.3-10.9-8.3c-2,0.6-5.8,3-10.4,2.3c-5.4-0.6-10.7-0.7-16.1-0.4
	c-6.1,0.4-12.3,0.2-18.4-0.5l-0.7-0.1c-9.1-1.2-14.3-4.4-13.9-17.1c0.5-15.9,0.3-30.6-0.6-44.6c0-0.9-0.2-1.8-0.2-2.7
	c-0.2-2-0.4-4-0.4-6c-0.1-3.6,1.3-7,3.8-9.5c2.5-2.3,5.7-3.5,9.9-3.4c7.9,0.2,15.9,0.7,23.7,1.1c13.6,0.8,27.7,1.6,41.4,1.1
	c2.9-0.1,7.4,1,8.9,0.9c5.8-0.3,9.3,1.4,15.6,1.1c0.8,0,1.6,0.3,2.2,0.6c2.2,1,4.8-0.1,5.7-2.3c0.1-0.3,0.2-0.5,0.3-0.8
	c0.2-1,0.7-11.2,0.8-12.1c1.3-10.7,0.8-13.1,2.6-22C1171.9,9.3,1170.5,10.3,1165.3,10.2z"
      />
      <path
        d="M800.5,89.8c10.4-6.4,15.6-6.7,27.9-10.3c1.7-0.6,3.5-0.9,5.3-0.9c2,0,4,0.5,5.6,1.7c4,2.8,4.4,7.6,4.6,9.9
	c0.5,6.6,0.7,13.2,0.8,19.6c0.1,6.1,0.2,12.5,0.7,18.6c0.7,9,0.4,18.1,0,26.8c-0.1,2.8-0.2,5.6-0.3,8.3c-0.4,14.2-0.1,28.7,0.2,42.7
	c0.2,8.8,0.3,17.5,0.4,26.3c0,5.4,0.2,11,0.4,16.4c0.6,16.8,1.2,34.2-3.4,51.7c0,0.2-0.1,0.4-0.1,0.5h0.7c11.5-1.2,26.5-3.2,41-7.3
	c5-1.4,6.4-4.2,4.9-9.5c-5.5-19.8-5.8-39.8-6.1-59.1c-0.1-5.6-0.2-11.3-0.4-16.9c-0.3-7.6,0-15.1,0.2-22.5c0.2-5.3,0.3-10.8,0.3-16
	c-0.1-14.8-0.2-33.1,1.6-50.8c0.5-5,0.9-10,1.3-15H886V91.6c0-4,0-8.1,0-12.1c0.1-9.7,2.7-16.3,15.1-17.3c3.5-0.3,6.9-0.7,10.6-1.2
	c10.3-1.4,20.9-2.1,32.1-0.1c1.5,0.3,2.4,0.5,2.4-0.7c0-1.4,0-3.5,0.1-3.6c0.8-11.8,0.5-10.7,1.4-18.4c0.5-4.5,0.2-10.6,0.3-14.4
	c0.2-4.6-1.3-4.6-6.3-4.1c-16,1.4-26.1,1.3-40.9,4.8l-0.8,0.2c-26.1,6.2-53.1,12.6-79.4,19.8c-3.2,0.9-6.4,2.1-9.7,3.4
	c-4.5,1.8-9.1,3.3-13.8,4.5c-1.2,0.2-3.9,0.8-4,5.9c0,0.8,0,1.6-0.1,2.3c-0.1,1.3-0.1,2.6,0,3.8c1.3,9.4,0.8,18.7-0.1,26.9
	c-0.1,0.9,0.5,1.7,1.4,1.8c0.3,0,0.7,0,1-0.2C797.3,91.6,800.1,90,800.5,89.8z"
      />
    </SVG>
  )
}

export default Logo
