import { createGlobalStyle } from 'styled-components'
import theme from '../styles/theme'
import fontFiles from '../fonts/fonts.js'

const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    /* stylelint-disable-next-line */
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    min-height: 0;
    min-width: 0;
	  box-sizing: border-box;
  }

  /* Fonts */

  @font-face {
    font-family: 'A';
    src: url(${fontFiles.apercuRegularEOT}) format('embedded-opentype');
    src: url(${fontFiles.apercuRegularTTF}) format("truetype"),
         url(${fontFiles.apercuRegularWOFF}) format("woff"),
         url(${fontFiles.apercuRegularWOFF2}) format("woff2");
   font-style: normal;
   font-weight: normal;
   font-display: fallback;
  }

  @font-face {
    font-family: 'A';
    src: url(${fontFiles.apercuMediumEOT}) format('embedded-opentype');
    src: url(${fontFiles.apercuMediumTTF}) format("truetype"),
         url(${fontFiles.apercuMediumWOFF}) format("woff"),
         url(${fontFiles.apercuMediumWOFF2}) format("woff2");
   font-style: normal;
   font-weight: bold;
   font-display: fallback;
  }

  @font-face {
    font-family: 'A';
    /*src: url(${fontFiles.apercuBoldEOT}) format('embedded-opentype');*/
    src: url(${fontFiles.apercuBoldTTF}) format("truetype"),
         url(${fontFiles.apercuBoldWOFF}) format("woff"),
         url(${fontFiles.apercuBoldWOFF2}) format("woff2");
   font-style: normal;
   font-weight: 900;
   font-display: fallback;
  }

  /* Site Specific Globals */
  html,
  body {
    /* stylelint-disable-next-line */
    -webkit-text-size-adjust: 100%;
    font-weight: normal;
    font-family: 'A',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    
  }

  body {
    font-size: 1em;
    color: ${theme.colors.base};
    line-height: 1;
    font-variant-ligatures: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }

  h1,h2,h3,h4,h5,h6 {
   /* font-family: 'A',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;*/
  }

  img {
    display: block;
  	width: 100%;
  	height: auto;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.base};
  }

  button,
  input,
  textarea,
  select {
    color: ${theme.colors.base};
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    appearance: none;
    /* stylelint-disable-next-line */
    -webkit-appearance: none;
    /* stylelint-disable-next-line */
    -moz-appearance: none;
    border-radius: 0;
    resize: none;
    &:invalid {
      box-shadow: none;
    }
    &:focus {
      outline: 5px auto #5E9ED6;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  a:focus {
    outline: 5px auto #5E9ED6;
    outline: 5px auto -webkit-focus-ring-color;
  }

  button {
    padding: 0;
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus,
  body:not(.user-is-tabbing) a:focus  {
    outline: none;
  }

  ::-webkit-input-placeholder {
    color: gray;
  }

  ::-moz-placeholder {
    color: gray; opacity: 1;
  }

  :-ms-input-placeholder {
    color: gray;
  }

  :-moz-placeholder {
    color: gray; opacity: 1;
  }

  select:invalid, select option[value=''] {
    color: gray !important;
  }


  /* Scrolling related clases */

  .contain {
    overflow: hidden;
  }

  /* Nprogress */

  .nprogress-busy {
    cursor: wait;
  }

  #nprogress .peg {
    display: none !important;
  }

  #nprogress .bar {
    z-index: 1000;
    height: 4px !important;
  }

  /* Menu Global Styling and Headroom Effects */

  .about .link--about, .Wedding .link--wedding, .Lifestyle .link--lifestyle, .contact .link--contact, .wedding-blog .link--blog, .lifestyle-blog .link--blog, .resources-blog .link--blog {
    border-bottom: 1px solid;
  }

  .scrolled {
    nav {
      background: white;
      border-color: ${theme.colors.secondary};
      a {
        color: ${theme.colors.base};
      }
    }
    #logo {
      svg {
        fill: ${theme.colors.base};
      }
    }
  }

.resources .top, .home .top, .about .top, .not-found .top, .privacy-policy .top  {
    @media screen and (min-width: ${theme.responsive.medium}) {
      nav a {
        color: ${theme.colors.base};
      }
      #logo {
        svg {
          fill: ${theme.colors.base};
        }
      }
    }
  }

  /* Hide Footer */
  .contact footer, .pricing footer {
    display: none;
  }
`
export default GlobalStyle
